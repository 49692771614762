import React, { useMemo } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import { Quotation } from 'src/types';

interface QuotationProductImageCellProps {
  item: Quotation;
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: 80,
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  }),
  { index: 999 }
);

const QuotationProductImageCell = ({
  item
}: QuotationProductImageCellProps) => {
  const classes = useStyles();

  const imageUrl = useMemo(() => {
    return (
      item?.product?.img_thumbnail ||
      '/static/images/products/product_not_found.png'
    );
  }, [item]);

  return (
    <TableCell className={classes.root}>
      {item?.product?.product_name ? (
        <img className={classes.image} src={imageUrl} alt="Product" />
      ) : null}
    </TableCell>
  );
};

export default React.memo(QuotationProductImageCell);
