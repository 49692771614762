/* eslint-disable no-undef */
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer
} from '@react-google-maps/api';
import { google_map_key, pcWorthMapLocation } from 'src/constants/map-location';
import { useMapLocation } from 'src/hooks/use-location';
import {
  Button,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { GoogleLatLng } from 'src/redux/slices/location/types';
import { NewBranchData } from 'src/types';
import { useSnackBar } from 'src/hooks';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

const containerStyle = {
  width: '100%',
  height: '400px'
};

interface Props {
  newMapLocationOrigin?: NewBranchData;
  getLatandLng?: (mark: GoogleLatLng) => void;
  getLatAndLngOrigin?: (mark: GoogleLatLng) => void;
}

const Component: FC<Props> = ({
  newMapLocationOrigin,
  getLatandLng,
  getLatAndLngOrigin
}) => {
  const snackBar = useSnackBar();
  const mapRef = useRef<google.maps.Map | null>(null);
  const { getAddressLatAndLang } = useMapLocation();
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_map_key || '',
    libraries: ['places'] // Include 'places' library
  });
  const [originLocation, setOriginLocation] = useState<GoogleLatLng>();
  const [currentExactLocation, setCurrentExactLocation] = useState<
    GoogleLatLng
  >();

  const [markerPosition, setMarkerPosition] = useState<GoogleLatLng>(
    pcWorthMapLocation
  );
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [
    directionsResponse,
    setDirectionsResponse
  ] = useState<google.maps.DirectionsResult | null>(null);
  const [isDirectionsLoaded, setDirectionsLoaded] = useState<boolean>(false);

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    mapRef.current = mapInstance; // Save the map instance
  }, []);

  const updateMarkerPosition = (
    lat: number,
    lng: number,
    origin?: GoogleLatLng
  ) => {
    setMarkerPosition({ lat, lng });

    fetchGeoCode(lat, lng);

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }

    if (getLatandLng) {
      getLatandLng({ lat, lng });
    }

    if (origin) {
      fetchDirections(origin, { lat, lng });
    }
  };

  const onZoomCurrentMap = (location?: GoogleLatLng, zoom?: number) => {
    if (mapRef.current && location) {
      mapRef.current.panTo(location);
      mapRef.current.setZoom(zoom || 20);
    }
  };

  const handlePlaceChanged = (origin?: GoogleLatLng) => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        onZoomCurrentMap({ lat, lng }, 10);
        setMarkerPosition({ lat, lng });
        setSearchAddress(place.formatted_address || '');
        setCurrentExactLocation({ lat, lng });

        if (getLatandLng) {
          getLatandLng({ lat, lng });
        }

        if (origin) {
          fetchDirections(origin, { lat, lng }, false); //false means we already fetched the zoom level
        }
      }
    }
  };

  // eslint-disable-next-line no-undef
  const handleMapClick = async (
    e: google.maps.MapMouseEvent,
    origin?: GoogleLatLng
  ) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setMarkerPosition({ lat, lng });

      // Fetch the address details for the clicked location
      fetchGeoCode(lat, lng);

      if (getLatandLng) {
        getLatandLng({ lat, lng });
      }

      if (origin) {
        fetchDirections(origin, { lat, lng });
      }
    }
  };

  const fetchGeoCode = (lat: number, lng: number) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const address = results[0].formatted_address;
        setSearchAddress(address); // Update the search address state with the new address
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const fetchMarkerPosition = useCallback(
    async (address?: string, currentLocation?: GoogleLatLng) => {
      try {
        const result = await getAddressLatAndLang(address);
        if (result && result?.location?.lat && result?.location?.lng) {
          const location = {
            lat: result?.location?.lat,
            lng: result?.location?.lng
          };

          setMarkerPosition({
            lat: result?.location?.lat,
            lng: result?.location?.lng
          });



          //this initialize the current origin address of the branch
          if (currentLocation && getLatAndLngOrigin) {
            getLatAndLngOrigin(location);
            setOriginLocation(location);
            setMarkerPosition(currentLocation);
            fetchDirections(location, currentLocation, false);
            return;
          }
          if (address && getLatAndLngOrigin) {
            getLatAndLngOrigin(location);
            setOriginLocation(location);
            setSearchAddress(''); //we dont need it to display on list
            fetchDirections(location, location); //reset when origin branch change

            if (getLatandLng) {
              getLatandLng({
                lat: result?.location?.lat,
                lng: result?.location?.lng
              });
            }
          } else {
            setSearchAddress(result?.formatted_address || '');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //badly needed disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAddressLatAndLang]
  );

  const fetchDirections = useCallback(
    async (
      origin: GoogleLatLng,
      destination: GoogleLatLng,
      exactLocationAction: boolean = true
    ) => {
      try {
        const service = await new google.maps.DirectionsService();

        if (origin && destination && service) {
          service.route(
            {
              origin,
              destination,
              travelMode: google.maps.TravelMode.DRIVING
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK && result) {
                setDirectionsResponse(result);
                //this set the point a and b zoom level if exact location is set
                const bounds = new google.maps.LatLngBounds();
                bounds.extend(origin);
                bounds.extend(destination);

                // Fit the map to the bounds
                if (mapRef?.current) {
                  mapRef.current.fitBounds(bounds);
                }

                if (exactLocationAction) {
                  onZoomCurrentMap(destination);
                }
              }
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  //preserve view
  const onDirectionsRendererLoad = () => {
    setTimeout(() => {
      setDirectionsLoaded(true);
    });
  };

  const onDirectionsRendererUnMount = () => {
    setDirectionsLoaded(false);
  };

  //pang screenshot galing kay google api
  const getStaticMapUrl = () => {
    const { lat, lng } = markerPosition; // Current marker position
    const apiKey = google_map_key; // Your API key
    const zoomLevel = 20; // Increased zoom level for closer view
    const size = '600x300'; // Image size
    const mapType = 'roadmap'; // Map type

    // Constructing the static map URL
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoomLevel}&size=${size}&maptype=${mapType}&markers=color:red%7Clabel:C%7C${lat},${lng}&key=${apiKey}`;

    return mapUrl;
  };

  const onScreenShot = async () => {
    const imageUrl = getStaticMapUrl();
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    navigator.clipboard
      ?.write([new ClipboardItem({ 'image/png': blob })])
      ?.then(() => {
        snackBar.show({
          severity: 'success',
          message: 'image copied to clipboard'
        });
      })
      .catch((err) => console.error('Could not copy image: ', err));
  };

  // Initialize component to center the location based on selected origin branch
  useEffect(() => {
    if (newMapLocationOrigin) {
      if (currentExactLocation) {
        fetchMarkerPosition(
          newMapLocationOrigin?.address,
          currentExactLocation
        );
        return;
      }

      fetchMarkerPosition(newMapLocationOrigin?.address);
    }
  }, [fetchMarkerPosition, newMapLocationOrigin, currentExactLocation]);

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  return (
    <>
      {isLoaded ? (
        <>
          <Typography
            variant="h4"
            style={{ marginBottom: '1rem' }}
          >{` Origin branch: ${newMapLocationOrigin?.address}`}</Typography>
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={() => handlePlaceChanged(originLocation)}
            restrictions={{ country: 'PH' }}
          >
            <TextField
              id="outlined-basic"
              label="Find exact location by typing complete address"
              variant="outlined"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              placeholder="Enter address..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Autocomplete>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={markerPosition}
            zoom={5}
            onLoad={onLoad}
            onClick={(e: any) => handleMapClick(e, originLocation)}
          >
            {markerPosition && (
              <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={(e) => {
                  if (e.latLng) {
                    const lat = e.latLng.lat();
                    const lng = e.latLng.lng();
                    updateMarkerPosition(lat, lng, originLocation);
                  }
                }}
              />
            )}
            {/* Ensure directionsResponse does not introduce another pin */}
            {directionsResponse && (
              <DirectionsRenderer
                directions={directionsResponse}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 6
                  },
                  preserveViewport: isDirectionsLoaded
                }}
                onLoad={onDirectionsRendererLoad}
                onUnmount={onDirectionsRendererUnMount}
              />
            )}
          </GoogleMap>

          <Button
            variant="outlined"
            color="primary"
            onClick={onScreenShot}
            startIcon={<AddAPhotoIcon />}
            style={{ marginTop: 5 }}
          >
            Take Screen Shot
          </Button>
        </>
      ) : (
        <div>Loading map...</div>
      )}
    </>
  );
};

export const GoogleMapComponent = memo(Component);
